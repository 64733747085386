import { createAsyncThunk, createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NOTIFICATION_SEPARATOR } from "_constants";

import { HYDRATE } from "next-redux-wrapper";
import PublicApi from "_api/publicApi";
import { statusIsGood } from "lib/middleware/errors";
import { NotificationState } from "_interfaces/notifications";
import { RootState } from "lib/store";
import { getNotesInLocation, retrieveStatesFromNotes } from "lib/notifications";
import _remove from "lodash/remove";
import PrivateApi from "_api/PrivateApi";

const initialState: NotificationState = {
  active: {}, // All ongoing notifications and states
  dismissed: {}, // All notes or states that have expired or been dismissed
  location: {
    header: [],
  },
};

const prepareNotificationState = (note) => {
  let timeNow = Date.now();

  return {
    ...note,
    key: note.state + NOTIFICATION_SEPARATOR + note.location + NOTIFICATION_SEPARATOR + timeNow,
  };
};

export const notificationAdd = createAction("notificationAdd", function prepare(payload) {
  return {
    payload: prepareNotificationState(payload),
  };
});
export const notificationDismiss = createAction<string>("notificationDismiss");
export const notificationDismissAllInLocation = createAction<string>("notificationDismissAllInLocation");
export const notificationDismissAll = createAction("notificationDismissAll");

export interface sendContactFormData {
  data: any;
  notification_handle: string;
}

export const sendRepEmail = createAsyncThunk<null, sendContactFormData>(
  "sendRepEmail",
  async (payload, { dispatch }) => {
    const { status, data } = await PublicApi.SendRepEmail(payload.user_id, payload.data);

    if (statusIsGood(status)) {
      dispatch(
        notificationAdd({
          location: payload.notification_handle,
          state: "success",
          messages: ["Email Sent"],
        })
      );
    } else {
      dispatch(
        notificationAdd({
          location: payload.notification_handle,
          state: "error",
          messages: ["We couldn't send your message right now. Please try again in a minute."],
        })
      );
    }

    return data;
  }
);

export interface sendGeneralContactFormData {
  data: ContactFormData;
  template?: "retail" | "flexible";
  subject?: string;
  notification_handle: string;
}

interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  company: string;
  iWouldLike?: string;
  date?: string;
  time?: string;
  propertyUse?: string;
  property_type?: string;
  property_id?: string;
  property_name?: string;
  size?: string;
  budget?: string;
  local_agents?: any;
  message: string;
  hasBid?: number;
  isPremium?: number;
}

export const generalContactEmail = createAsyncThunk<null, sendGeneralContactFormData>(
  "generalContactEmail",
  async (payload, { dispatch }) => {
    try {
      const { status, data } = await PublicApi.generalContactEmail(payload.template, payload.data, payload.subject);

      if (statusIsGood(status)) {
        dispatch(
          notificationAdd({
            location: payload.notification_handle,
            state: "success",
            messages: ["Email Sent"],
          })
        );
      } else {
        dispatch(
          notificationAdd({
            location: payload.notification_handle,
            state: "error",
            messages: ["We couldn't send your message right now. Please try again in a minute."],
          })
        );
      }

      return data;
    } catch {
      dispatch(
        notificationAdd({
          location: payload.notification_handle,
          state: "error",
          messages: ["We couldn't send your message right now. Please try again in a minute."],
        })
      );
    }
  }
);

export const propertyContactEmail = createAsyncThunk<null, sendGeneralContactFormData>(
  "generalContactEmail",
  async (payload, { dispatch }) => {
    try {
      //const { status, data } = await PrivateApi.propertyContactEmail(payload.data);

      const response = await fetch(`${process.env.NEXT_PUBLIC_CR_JS_API}enquiry/property`, {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(payload.data),
        credentials: "include"
      })
      response.json().then(data => {
        if (response.status && statusIsGood(response.status)) {
          dispatch(
            notificationAdd({
              location: payload.notification_handle,
              state: "success",
              messages: ["Email Sent"],
            })
          );
        } else {
          dispatch(
            notificationAdd({
              location: payload.notification_handle,
              state: "error",
              messages: ["We couldn't send your message right now. Please try again in a minute."],
            })
          );
        }
        return data;
      })
    } catch {
      dispatch(
        notificationAdd({
          location: payload.notification_handle,
          state: "error",
          messages: ["We couldn't send your message right now. Please try again in a minute."],
        })
      );
    }
  });
  

export const agentContactEmail = createAsyncThunk<null, sendGeneralContactFormData>("generalContactEmail", async (payload, { dispatch }) => {
  try {
    const { status, data } = await PrivateApi.agentContactEmail(payload.data);

    if (statusIsGood(status)) {
      dispatch(notificationAdd({
        location: payload.notification_handle,
        state: 'success',
        messages: [
          "Email Sent"
        ]
      }))
    } else {
      dispatch(notificationAdd({
        location: payload.notification_handle,
        state: 'error',
        messages: [
          "We couldn't send your message right now. Please try again in a minute."
        ]
      }))
    }

    return data;
  } catch {
    dispatch(
      notificationAdd({
        location: payload.notification_handle,
        state: "error",
        messages: ["We couldn't send your message right now. Please try again in a minute."],
      })
    );
  }
});

//temp
export const propertyUpgradeAction = createAsyncThunk<null, sendGeneralContactFormData>(
  "generalContactEmail",
  async (payload, { dispatch }) => {
    const { status, data } = await PublicApi.propertyContactEmail(payload.template, payload.data, payload.subject);

    if (statusIsGood(status)) {
      dispatch(
        notificationAdd({
          location: payload.notification_handle,
          state: "success",
          messages: ["Email Sent"],
        })
      );
    } else {
      dispatch(
        notificationAdd({
          location: payload.notification_handle,
          state: "error",
          messages: ["We couldn't send your message right now. Please try again in a minute."],
        })
      );
    }

    return data;
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(notificationAdd, (state, action) => {
        let { key, ...noteToAdd } = action.payload;
        state.active = {
          ...state.active,
          [key]: noteToAdd,
        };
        if (!state.location[noteToAdd.location]) {
          state.location[noteToAdd.location] = [];
        }
        state.location[noteToAdd.location].unshift(key);
      })
      .addCase(notificationDismissAllInLocation, (state, action) => {
        let notesToDismiss = state.location[action.payload];
        state.location[action.payload] = [];
        if (notesToDismiss) {
          notesToDismiss.forEach((noteId) => {
            state.dismissed[noteId] = state.active[noteId];
            delete state.active[noteId];
          });
        }
      })
      .addCase(notificationDismiss, (state, action) => {
        let noteId = action.payload;
        let note = state.active[noteId];

        state.location[note.location] = _remove(state.location[note.location], (id) => id !== noteId);
        state.dismissed[noteId] = note;
        delete state.active[noteId];
      })
      .addCase(notificationDismissAll, (state, action) => {
        state.dismissed = {
          ...state.dismissed,
          ...state.active,
        };
        state.active = {};
        for (let location in state.location) {
          state.location[location] = [];
        }
      });
  },
});

export const hasSuccessInLocation = (noteLocation: string, state: RootState): boolean => {
  let notes = getActiveNotificationsInLocation(noteLocation, state);
  return retrieveStatesFromNotes(notes).success ? true : false;
};

export const getActiveNotificationsInLocation = (noteLocation: string, state: RootState) => {
  return getNotesInLocation(noteLocation, state.notifications.active);
};

export const getActiveNotificationsMessagesById = (noteId: string, state: RootState) => {
  return state.notifications.active[noteId] ? state.notifications.active[noteId].messages : null;
};

export default notificationsSlice.reducer;
